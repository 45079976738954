<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">收款结算查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="结算单号" class="searchboxItem ci-full">
              <span class="itemLabel">结算单号:</span>
              <el-input v-model.trim="searchData.billNo" clearable placeholder="请输入结算单号" size="small"></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model.trim="searchData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="付款机构" class="searchboxItem ci-full">
              <span class="itemLabel">付款机构:</span>
              <el-input v-model.trim="searchData.payerName" clearable placeholder="请输入付款机构" size="small"></el-input>
            </div>
            <div title="到账状态" class="searchboxItem ci-full">
              <span class="itemLabel">到账状态:</span>
              <el-select size="small" v-model="searchData.accountTypes" multiple collapse-tags clearable
                placeholder="请选择到账状态">
                <el-option v-for="item in settlementArrivedList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="是否记账" class="searchboxItem ci-full">
              <span class="itemLabel">是否记账:</span>
              <el-select size="small" v-model="searchData.chargeUp" clearable placeholder="请选择是否记账">
                <el-option v-for="item in yesnoList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="有无原单" class="searchboxItem ci-full">
              <span class="itemLabel">有无原单:</span>
              <el-select size="small" v-model="searchData.withBill" clearable placeholder="请选择有无原单">
                <el-option v-for="item in yesnoList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="有无照片" class="searchboxItem ci-full">
              <span class="itemLabel">有无照片:</span>
              <el-select size="small" v-model="searchData.withImg" clearable placeholder="请选择有无照片">
                <el-option v-for="item in yesnoList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="开票状态" class="searchboxItem ci-full">
              <span class="itemLabel">开票状态:</span>
              <el-select size="small" v-model="searchData.billings" multiple collapse-tags clearable
                placeholder="请选择开票状态">
                <el-option v-for="item in settlementmakeOutAnInvoiceList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="所属年度" class="searchboxItem ci-full">
              <span class="itemLabel">所属年度:</span>
              <el-select size="small" v-model="searchData.affiliationYear" placeholder="请选择选择年份" clearable><el-option
                  v-for="item in affiliationYearList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="现负责人" class="searchboxItem ci-full">
              <span class="itemLabel">现负责人:</span>
              <el-select size="small" v-model.trim="searchData.currentPrincipal" remote
                :remote-method="getfindSaleManList" filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in findSaleManList" :key="item.saleId" :label="item.saleName"
                  :value="item.saleId"></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-select size="small" v-model="searchData.collectionId" clearable placeholder="请选择培训类型">
                <el-option v-for="item in trainTypeList" :key="item.collectionId" :label="item.collectionName"
                  :value="item.collectionId"></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader clearable filterable v-model="searchData.areaId" :options="areatreeList" :props="propsarea"
                size="small"></el-cascader>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级归属业务员" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">班级归属业务员:</span>
              <el-select size="small" v-model.trim="searchData.marketId" remote :remote-method="getfindSaleManList"
                filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in findSaleManList" :key="item.saleId" :label="item.saleName"
                  :value="item.saleId"></el-option>
              </el-select>
            </div>
            <div title="收款日期" class="searchboxItem ci-full">
              <span class="itemLabel">收款日期:</span>
              <el-date-picker clearable size="small" v-model="searchData.collectMoneyDate" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker clearable size="small" v-model="searchData.createTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="代付款单位" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">代付款单位:</span>
              <el-input v-model.trim="searchData.replacePayerName" clearable placeholder="请输入代付款单位"
                size="small"></el-input>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="doExport()">导出</el-button>
            </div>
          </div>
          <div class="searchbox">
            <div title="收款金额" class="searchboxItem ci-full">
              <span class="itemLabel">收款金额:</span>
              <div style="font-size:12px">{{ collectionBillCount.collectionTotal }}元</div>
            </div>
            <div title="欠款金额" class="searchboxItem ci-full">
              <span class="itemLabel">欠款金额:</span>
              <div style="font-size:12px">{{ collectionBillCount.arrearsAmountTotal }}元</div>
            </div>
            <div title="已开票金额" class="searchboxItem ci-full">
              <span class="itemLabel">已开票金额:</span>
              <div style="font-size:12px">{{ collectionBillCount.invoiceFeeTotal || 0 }}元</div>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="结算单号" align="left" show-overflow-tooltip prop="billNo" min-width="180px" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="180px" />
              <el-table-column label="付款机构名称" align="left" show-overflow-tooltip prop="payerName" min-width="180px" />
              <el-table-column label="代付款单位" align="left" show-overflow-tooltip prop="replacePayerName"
                min-width="180px" />
              <el-table-column label="收款标准" align="left" show-overflow-tooltip prop="chargesSettlement"
                min-width="180px" />
              <el-table-column label="收款金额" align="left" show-overflow-tooltip prop="payerAmount" min-width="80px" />
              <el-table-column label="平台结算金额" align="left" show-overflow-tooltip prop="settlementAmount"
                min-width="100px" />
              <el-table-column label="到账金额" align="left" show-overflow-tooltip prop="arrivalAmount" min-width="80px" />
              <el-table-column label="欠款金额" align="left" show-overflow-tooltip prop="arrearsAmount" min-width="80px" />
              <el-table-column label="收款日期" align="left" show-overflow-tooltip prop="payeeDate" min-width="100px">
                <template slot-scope="{row}">
                  {{ row.payeeDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="是否开票" align="left" show-overflow-tooltip prop="billing" min-width="80px">
                <template slot-scope="{ row }">
                  {{ $setDictionary('IS_INVOICE_STATE', row.billing) }}
                </template>
              </el-table-column>
              <el-table-column label="开票金额" align="left" show-overflow-tooltip prop="invoiceFee" min-width="80px" />
              <el-table-column label="开票日期" align="left" show-overflow-tooltip prop="invoiceDate" min-width="100px">
                <template slot-scope="{row}">
                  {{ row.invoiceDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="结算人数" align="left" show-overflow-tooltip prop="settlementUserCount"
                min-width="80px" />
              <el-table-column label="省" align="left" show-overflow-tooltip prop="province" min-width="80px" />
              <el-table-column label="市" align="left" show-overflow-tooltip prop="city" min-width="100px" />
              <el-table-column label="县(区)" align="left" show-overflow-tooltip prop="county" min-width="100px" />
              <el-table-column label="班级归属业务员" align="left" show-overflow-tooltip prop="marketName" min-width="120px" />
              <el-table-column label="现负责人" align="left" show-overflow-tooltip prop="currentPrincipalName"
                min-width="100px" />
              <el-table-column label="所属年度" align="left" show-overflow-tooltip prop="affiliationYear" min-width="80px" />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="collectionName" min-width="180px" />
              <el-table-column label="有无照片" align="left" show-overflow-tooltip prop="withImg" min-width="80px" />
              <el-table-column label="上传照片日期" align="left" show-overflow-tooltip prop="withImgTime" min-width="150px" />
              <el-table-column label="有无原单" align="left" show-overflow-tooltip prop="withBill" min-width="80px" />
              <el-table-column label="上传原单日期" align="left" show-overflow-tooltip prop="withBillTime" min-width="150px" />
              <el-table-column label="是否记账" align="left" show-overflow-tooltip prop="chargeUp" min-width="80px">
                <template slot-scope="{ row }">
                  {{ row.chargeUp === false ? '否' : row.chargeUp === true ? '是' : '--' }}
                </template>
              </el-table-column>
              <el-table-column label="结算单状态" align="left" show-overflow-tooltip prop="auditType" min-width="120px">
                <template slot-scope="{ row }">
                  {{ $setDictionary('FD_AUDIT_TYPE', row.auditType) }}
                </template>
              </el-table-column>
              <el-table-column label="到账经办日期" align="left" show-overflow-tooltip prop="arrivalDate" min-width="150px" />
              <el-table-column label="是否折扣" align="left" show-overflow-tooltip prop="discounted" min-width="80px">
                <template slot-scope="{ row }">
                  {{ row.discounted === false ? '否' : row.discounted === true ? '是' : '--' }}
                </template>
              </el-table-column>
              <el-table-column label="结算单收回日期" align="left" show-overflow-tooltip prop="billRecycleDate"
                min-width="110px">
                <template slot-scope="{row}">
                  {{ row.billRecycleDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="票据类型" align="left" show-overflow-tooltip prop="invoiceType" min-width="80px">
                <template slot-scope="{ row }">
                  {{ $setDictionary('INVOICE_TYPE', row.invoiceType) }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "settlementPaymentQueries",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  data() {
    return {
      //查询条件
      searchData: {
        billNo: "",//结算单号
        payerName: "",//付款机构名称
        areaId: "",//区划id
        compId: "",//机构名称
        chargeUp: "",//是否记账
        withBill: "",//有无原单
        withImg: "",//有无照片
        collectMoneyDate: "",//收款日期
        createTime: "",//创建日期
        collectionId: "",//培训类型
        accountTypes: [],//到账状态
        billings: [],//开票状态
        marketId: "",//班级归属业务员
        currentPrincipal: "",//现负责人
        affiliationYear: "",//所属年度
        replacePayerName: "", //代付款单位
      },
      CompanyList: [],//机构名称list
      trainTypeList: [],//培训类型list
      settlementmakeOutAnInvoiceList: [],//开票状态list
      findSaleManList: [],//班级业务员和现负责人list
      affiliationYearList: [],//所属年度list
      settlementArrivedList: [//到账状态list
        {
          label: "已到账",
          value: "10",
        },
        {
          label: "未到账",
          value: "30",
        },
        {
          label: "部分到账",
          value: "20",
        },
      ],
      yesnoList: [//是否list
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      areatreeList: [],//行政区划list
      collectionBillCount: {
        arrearsAmountTotal: 0,//欠款金额
        collectionTotal: 0,//收款金额
      },//统计
      propsarea: {//行政区划prop
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  watch: {},
  created() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getsettlementmakeOutAnInvoiceList();
    this.getareatree();
    this.getTraintype();
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/category/trainTypeCollection/getTrainTypeCollection").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    /* 获取班级业务员和现负责人list */
    getfindSaleManList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/new/bill/contract/findSaleManList", { saleName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.findSaleManList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.findSaleManList = [];
      }
    },
    //开票状态list获取
    getsettlementmakeOutAnInvoiceList() {
      const settlementmakeOutAnInvoiceList = this.$setDictionary("IS_INVOICE_STATE", "list");
      const list = [];
      for (const key in settlementmakeOutAnInvoiceList) {
        list.push({
          value: key,
          label: settlementmakeOutAnInvoiceList[key],
        });
      }
      this.settlementmakeOutAnInvoiceList = list;
      // 设置所属年度
      let arr1 = this.$setDictionary("BILL_AFFILIATION_YEAR", "list");
      for (const key in arr1) {
        if (arr1[key] == "999") {
          this.affiliationYearList.push({
            label: "未设置",
            value: arr1[key],
          });
        } else {
          this.affiliationYearList.push({
            label: arr1[key],
            value: arr1[key],
          });
        }
      }
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    //统计
    getcollectionBillCount(params) {
      this.$post("/biz/new/bill/collectionBillCount", params).then((res) => {
        if (res.data) {
          this.collectionBillCount = res.data;
        } else {
          this.collectionBillCount = {
            arrearsAmountTotal: 0,//欠款金额
            collectionTotal: 0,//收款金额
          }
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        chargeUp: this.searchData.chargeUp,
        withBill: this.searchData.withBill,
        withImg: this.searchData.withImg,
      };
      if (this.searchData.billNo) {//结算单号
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payerName) {//付款机构名称
        params.payerName = this.searchData.payerName;
      }
      if (this.searchData.areaId) {//区划id
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.accountTypes?.length > 0) {//到账状态
        params.accountTypes = this.searchData.accountTypes;
      }
      if (this.searchData.collectionId) {//培训类型
        params.collectionId = this.searchData.collectionId;
      }
      if (this.searchData.compId) {//机构名称
        params.compId = this.searchData.compId;
      }
      if (this.searchData.marketId) {//班级归属业务员
        params.marketId = this.searchData.marketId;
      }
      if (this.searchData.currentPrincipal) {//现负责人
        params.currentPrincipal = this.searchData.currentPrincipal;
      }
      if (this.searchData.billings?.length > 0) {//开票状态
        params.billings = this.searchData.billings;
      }
      if (this.searchData.affiliationYear) {//所属年度
        params.affiliationYear = this.searchData.affiliationYear;
      }
      if (this.searchData.createTime) {//创建日期
        params.createTimeStart = this.searchData.createTime[0];
        params.createTimeEnd = this.searchData.createTime[1];
      }
      if (this.searchData.collectMoneyDate) {//收款日期
        params.payeeDateStart = this.searchData.collectMoneyDate[0];
        params.payeeDateEnd = this.searchData.collectMoneyDate[1];
      }
      if (this.searchData.replacePayerName) {//代付款单位
        params.replacePayerName = this.searchData.replacePayerName;
      }
      this.doFetch({
        url: "/biz/new/bill/collectionBillList",
        params,
        pageNum,
      });
      this.getcollectionBillCount(params)
    },
    // 导出
    doExport() {
      const params = {
        chargeUp: this.searchData.chargeUp,
        withBill: this.searchData.withBill,
        withImg: this.searchData.withImg,
      };
      if (this.searchData.billNo) {//结算单号
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payerName) {//付款机构名称
        params.payerName = this.searchData.payerName;
      }
      if (this.searchData.areaId) {//区划id
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.accountTypes?.length > 0) {//到账状态
        params.accountTypes = this.searchData.accountTypes;
      }
      if (this.searchData.collectionId) {//培训类型
        params.collectionId = this.searchData.collectionId;
      }
      if (this.searchData.compId) {//机构名称
        params.compId = this.searchData.compId;
      }
      if (this.searchData.marketId) {//班级归属业务员
        params.marketId = this.searchData.marketId;
      }
      if (this.searchData.currentPrincipal) {//现负责人
        params.currentPrincipal = this.searchData.currentPrincipal;
      }
      if (this.searchData.billings?.length > 0) {//开票状态
        params.billings = this.searchData.billings;
      }
      if (this.searchData.affiliationYear) {//所属年度
        params.affiliationYear = this.searchData.affiliationYear;
      }
      if (this.searchData.createTime) {//创建日期
        params.createTimeStart = this.searchData.createTime[0];
        params.createTimeEnd = this.searchData.createTime[1];
      }
      if (this.searchData.collectMoneyDate) {//收款日期
        params.payeeDateStart = this.searchData.collectMoneyDate[0];
        params.payeeDateEnd = this.searchData.collectMoneyDate[1];
      }
      if (this.searchData.replacePayerName) {//代付款单位
        params.replacePayerName = this.searchData.replacePayerName;
      }
      this.$post("/biz/new/bill/collectionBillExport", params)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (4 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 9) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 45;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .operationControl>div span>span {
  min-width: 0 !important;
}

.itemLabel {
  min-width: 5rem;
}

.upload-btns {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.el-textarea__inner {
  min-height: 7.5rem !important;
  resize: none;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.dialog-footer {
  margin-top: .75rem;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;

    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;

      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
